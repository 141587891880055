export const environment = {
    production: false,
    // baseUrl: 'https://prod-api.finshakti.in/',
    baseUrl: 'https://dev-api.finshakti.com/',
    // baseUrl: 'https://stage-lagubandhu.finshakti.com/',


    sasToken:
        '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupitfx&se=2030-09-05T14:32:57Z&st=2024-05-21T06:32:57Z&spr=https,http&sig=4z4Vey4sb9S8tXPL0y0GI0LeIFR1HXZKPEe49YI4gxA%3D',
    accountName: 'laghubandhu',
    containerName: 'dev-lagubandhu',
    imageUploadSize: 1000000,
    firebaseConfig: {
        apiKey: "AIzaSyCjQuQN1TwWv0DMmMTIY6VRgSv_WplLR9A",
        authDomain: "finshakti-ssp.firebaseapp.com",
        projectId: "finshakti-ssp",
        storageBucket: "finshakti-ssp.appspot.com",
        messagingSenderId: "710088896028",
        appId: "1:710088896028:web:e4228284f0642e2ee1f9d9",
        measurementId: "G-3XBV4ENER3"
    },

    domainEndPoint: ".dev.finshakti.com",
    erpEndPoint: ".devssp.finshakti.com"
}