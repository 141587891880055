import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(
    private http: HttpClient,
  ) {}

  
  put(url: string, data: any): Observable<any> {
    
    const put$ = new ReplaySubject<any>();
    this.http.put(url, data).subscribe({
      next: (response) => {
        put$.next(response);
      },
      complete: () => {},
      error: (err) => {
        // console.log('possible: global handle err/warning with toast');
        put$.error(err);
      },
    });

    return put$.asObservable();
  }

  get(url: string): Observable<any> {
    
    const get$ = new ReplaySubject<any>();
    this.http.get(url).subscribe({
      next: (response) => {
        get$.next(response);
        
      },
      complete: () => {},
      error: (err) => {
        get$.error(err);
      },
    });

    return get$.asObservable();
  }

  post(url:string, data:any):Observable<any>{
    
    const post$ = new ReplaySubject<any>();
    this.http.post(url,data).subscribe({
      next:(response) => {
        post$.next(response);
        
      },
      complete : () => {},
      error:(err) => {
        // console.log('possible: global handle err/warning with toast');
        post$.error(err);
      }
    });

    return post$;
  }
}

